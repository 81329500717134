<template>
  <div>
    <v-dialog
      v-model="getDialog"
      ref="dialog"
      :width="boxWidth"
      content-class="dialog-popup radio-dialog checkbox-popup"
      @click:outside="handleClickOutSite"
    >
      <div class="head-dialog-popup">
        <span class="head-image-dialog-popup__text over-text-sp">{{descriptionText}}</span>
        <div class="selected-count over-text-sp">{{ getSelectedData.length }}{{ $t("a_export_report.label_items_are_being_selected") }}</div>
      </div>
      <hr class="divider" />
      <div class="content-dialog-popup">
        <div
          v-if="!isSelectMethods"
          class="selection-pulldown"
          id="style-scroll"
          :class="[methodsList.length > 8 ? '' : 'item-scroll-none', width === '50%' ? '' : 'method-list', isCollapsedProp ? '' : 'dont-collapse']"
        > 
          <div v-if="hasSearch" class="search-text">
            <input v-model="searchText" type="text" class="input-elic" :placeholder="searchPlacehoder" style="width: 100%;">
            <img :src="require('@/assets/icons/pulldown/search-icon.svg')" alt="search icon" class="search-icon">
          </div>
          <v-virtual-scroll bench="3" :items="methodsList" :item-height="41" :height="methodsList.length < 11 ? 41 * methodsList.length : 41 * 11"
          min-width="94">
            <template v-slot:default="{ item }">
              <div class="item pull-down-text-color">
                <div v-if="item.hasCheckbox" class="export-checkbox" :class="{'is-checked': isCheckedBox(item.text), 'is-checked-getall' : (isGetAll(item.text) && itemCount === getSelectedData.length)}">
                  <input v-if="isGetAll(item.text)" v-model="getAllData" type="checkbox" :label="item.row_num_pattern" :value="item.text" :id="`${item.text}_${hasSearch}`" />
                  <input v-else v-model="getSelectedData" type="checkbox" :label="item.row_num_pattern" :value="item.text" :id="`${item.text}_${hasSearch}`" />
                  <label :for="`${item.text}_${hasSearch}`">{{ item.text }}</label>
                </div>
                <div v-else class="toggle-wrapper layer1">
                  <div v-if="isCollapsedProp" class="toggle-label" @click="toggleCollapse(index)" :class="isActiveMethod(item.text) && 'is-checked'">
                    <span>{{ item.text }}</span>
                    <img :src="getArrowImage()" alt="" class="toggle-icon" :class="{'arrow-top': isCollapsed[index]}" />
                  </div>
                  <div class="content-container" v-if="isCollapsed[index] || !isCollapsedProp">
                    <div
                      class="item pull-down-text-color"
                      v-for="(itemLayer1, layer1Index) in getMethodLayer1(index)"
                      :key="layer1Index"
                    >
                    <div v-if="itemLayer1.hasCheckbox" class="export-checkbox" :class="{'is-checked': isCheckedBox(`${item.text}_${itemLayer1.text}_${itemLayer1.row_num_pattern}`)}">
                      <input v-model="getSelectedData" type="checkbox" :label="itemLayer1.row_num_pattern" :value="`${item.text}_${itemLayer1.text}_${itemLayer1.row_num_pattern}`" :id="`${item.text}_${itemLayer1.text}_${hasSearch}_${itemLayer1.row_num_pattern}`" />
                      <label :for="`${item.text}_${itemLayer1.text}_${hasSearch}_${itemLayer1.row_num_pattern}`" style="width: 100%;">{{ itemLayer1.text }}</label>
                    </div>
                    <div v-else class="toggle-wrapper layer2">
                      <div class="toggle-label" @click="toggleCollapseLayer1(index + layer1Index)" :class="isActiveMethod(item.text + '_' + itemLayer1.text) && 'is-checked ' + item.text + '_' + itemLayer1.text">
                        <span>{{ itemLayer1.text }}</span>
                        <img v-if="isCollapsedProp" :src="getArrowImage()" alt="" class="toggle-icon" :class="{'arrow-top': isCollapsedLayer1[index + layer1Index]}" />
                      </div>
                      <div class="content-container" v-if="isCollapsedLayer1[index + layer1Index] || !isCollapsedProp">
                        <div
                          class="item pull-down-text-color"
                          v-for="(itemLayer2, layer2Index) in getMethodLayer2(layer1Index, index)"
                          :key="layer2Index"
                        >
                        <div v-if="itemLayer2.hasCheckbox" class="export-checkbox" :class="{'is-checked': isCheckedBox(item.text + '_' + itemLayer1.text + '_' + itemLayer2.text)}">
                          <input v-model="getSelectedData" type="checkbox" :label="itemLayer2.row_num_pattern" :value="item.text + '_' + itemLayer1.text + '_' + itemLayer2.text" :id="`${item.text}_${itemLayer1.text}_${itemLayer2.text}_${hasSearch}`" />
                          <label :for="`${item.text}_${itemLayer1.text}_${itemLayer2.text}_${hasSearch}`" style="width: 100%;">{{ itemLayer2.text }}</label>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </template>
          </v-virtual-scroll>
        </div>
        <div
          v-else
          class="selection-pulldown"
          id="style-scroll"
          :class="[methodsList.length > 8 ? '' : 'item-scroll-none', width === '50%' ? '' : 'method-list', isCollapsedProp ? '' : 'dont-collapse', isSelectMethods ? 'select-methods' : '']"
        >
          <div
            v-for="(item, index) in methodsList"
            :key="index"
            class="item pull-down-text-color"
          >
          <div v-if="item.hasCheckbox" class="export-checkbox" :class="{'is-checked': isCheckedBox(item.text), 'is-checked-getall' : (isGetAll(item.text) && itemCount === getSelectedData.length)}">
              <input v-if="isGetAll(item.text)" v-model="getAllData" @input="handleCheckAll($event)" type="checkbox" :label="item.row_num_pattern" :value="item.text" :id="`${item.text}_${hasSearch}`" />
              <input v-else v-model="getSelectedData" type="checkbox" :label="item.row_num_pattern" :value="item.text" :id="`${item.text}_${hasSearch}`" />
              <label :for="`${item.text}_${hasSearch}`">{{ item.text }}</label>
            </div>
            <div v-else class="toggle-wrapper layer1">
              <div v-if="isCollapsedProp" class="toggle-label export-checkbox-multi-method"  :class="isActiveMethod(item.text) && 'is-checked'">
                <div v-if="item.methods.length" class="export-checkbox" :class="{'is-checked': item.selectAll }" style="padding: 0">
                  <input v-model="item.selectAll" @input="checkAllSelectedGr($event, index)" type="checkbox" :label="item.row_num_pattern" :value="item.text" :id="`${item.text}_${hasSearch}`" />
                  <label :for="`${item.text}_${hasSearch}`">{{ item.text }}</label>
                </div>
                <img :src="getArrowImage()" alt="" @click="toggleCollapse(index)" class="toggle-icon" :class="{'arrow-top': isCollapsed[index]}" />
              </div>
              <div class="content-container" v-if="isCollapsed[index] || !isCollapsedProp">
                <div
                  class="item pull-down-text-color"
                  v-for="(itemLayer1, layer1Index) in getMethodLayer1(index)"
                  :key="layer1Index"
                >
                <div v-if="itemLayer1.hasCheckbox" class="export-checkbox" :class="{'is-checked': isCheckedBox(item.text + '_' + itemLayer1.text)}">
                  <input v-model="getSelectedData" @input="checkedItemGr($event, item, index, layer1Index)" type="checkbox" :label="itemLayer1.row_num_pattern" :value="item.text + '_' + itemLayer1.text" :id="`${item.text}_${itemLayer1.text}_${hasSearch}`" />
                  <label :for="`${item.text}_${itemLayer1.text}_${hasSearch}`" style="width: 100%;">{{ itemLayer1.text }}</label>
                </div>
                <div v-else class="toggle-wrapper layer2">
                  <div class="toggle-label" :class="isActiveMethod(item.text + '_' + itemLayer1.text) && 'is-checked'">
                    <div v-if="itemLayer1.methods.length" class="export-checkbox" :class="{'is-checked': itemLayer1.selectAll }" style="padding: 0">
                      <input v-model="itemLayer1.selectAll" @input="checkAllSelectedItemGr($event, index, layer1Index)" type="checkbox" :label="itemLayer1.row_num_pattern" :value="`${itemLayer1.text}_${layer1Index}`" :id="`${item.text}_${itemLayer1.text}_${hasSearch}`" />
                      <label :for="`${item.text}_${itemLayer1.text}_${hasSearch}`">{{ itemLayer1.text }}</label>
                    </div>
                    <img v-if="isCollapsedProp" @click="toggleCollapseLayer1(index + layer1Index)" :src="getArrowImage()" alt="" class="toggle-icon" :class="{'arrow-top': isCollapsedLayer1[index + layer1Index]}" />
                  </div>
                  <div class="content-container export-checkbox-multi-method" v-if="isCollapsedLayer1[index + layer1Index] || !isCollapsedProp">
                    <div
                      class="item pull-down-text-color"
                      v-for="(itemLayer2, layer2Index) in getMethodLayer2(layer1Index, index)"
                      :key="layer2Index"
                    >
                    <div v-if="itemLayer2.hasCheckbox" class="export-checkbox" :class="{'is-checked': isCheckedBox(item.text + '_' + itemLayer1.text + '_' + itemLayer2.text)}">
                      <input v-model="getSelectedData" @input="selectedCheckbox($event, itemLayer1, item)" type="checkbox" :label="itemLayer2.row_num_pattern" :value="item.text + '_' + itemLayer1.text + '_' + itemLayer2.text" :id="`${item.text}_${itemLayer1.text}_${itemLayer2.text}_${hasSearch}`" />
                      <label :for="`${item.text}_${itemLayer1.text}_${itemLayer2.text}_${hasSearch}`" style="width: 100%;">{{ itemLayer2.text }}</label>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      <div class="action-dialog-popup button-bottom-dialog-popup">
        <common-button class="cancle-btn" @action="close" :label="labelBtnCancel"></common-button>
        <common-button class="confirm" @action="submit" type="colored" :label="confirmText"></common-button>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import {GET_ALL} from '@/constants/export-report.js'
import CommonButton from '@/components/utils/button.vue';
import i18n from '@/lang/i18n';
export default {
  components: { CommonButton },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    confirmText: {
      type: String,
      default: i18n.t('list_menu.label_decision'),
    },
    methods: {
      type: Array,
      default: () => [],
    },
    descriptionText: {
      type: String,
      default: '',
    },
    hasSearch: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '100%'
    },
    isCollapsedProp: {
      type: Boolean,
      default: true,
    },
    selectedData: {
      type: Array,
      default: () => [],
    },
    itemCount: {
      type: Number,
      default: 0
    },
    searchPlacehoder: {
      type: String,
      default: '法人を検索'
    },
    isReduction: {
      type: Boolean,
      default: false,
    },
    getAllText: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      boxWidth: 0,
      resizeTimer: null,
      isCollapsed: [],
      isCollapsedLayer1: [],
      searchText: '',
      selectionShow: false,
      getSelectedData : [],
      methodsList: [],
      getAllData: false,
      isChangeGetAll: false
    };
  },
  mounted() {
    this.methodsList = this.methods;
    this.setCheckedCountWhenHasData(this.getSelectedData);

    this.getSelectedData = [...this.selectedData];
    this.boxWidth = this.getWidth();
    this.onWindowResize();
    if(this.getSelectedData.length === this.itemCount && this.getSelectedData.length > 0 && this.itemCount > 0) {
      this.getAllData = true;
    }

    
  },
  computed: {
    getDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('update:dialog', value);
      },
    },
    labelBtnCancel() {
      return this.$t("a_export_report.button_cancel");
    },
    isSelectMethods() {
      return this.type === 'listScopeCategories';
    }
  },
  watch: {
    searchText: {
      handler(value) {
        this.methodsList = this.methods.filter(item => item.text.toLowerCase().includes(value.toLowerCase()));
      }
    },
    getAllData: {
      handler(newValue) {
        if (newValue) {
          this.getSelectedData.length = 0;
          this.methodsList.map(item => {
            if (!item?.methods) {
              this.getSelectedData.push(`${item.text}`);
            } else {
              item?.methods?.map(itemLayer1 => {
                if (!itemLayer1?.methods) {
                  this.getSelectedData.push(`${item.text}_${itemLayer1.text}`);
                } else {
                  itemLayer1?.methods?.map(itemLayer2 => {
                    this.getSelectedData.push(`${item.text}_${itemLayer1.text}_${itemLayer2.text}`);
                  })
                  // this.$set(itemLayer1, 'selectAll', true);
                  // this.$set(itemLayer1, 'itemChecked', itemLayer1?.methods.length);
                }
              })
            }
          })
          const existIndex = this.getSelectedData.indexOf(this.isReduction ? this.getAllText : GET_ALL);
          if (existIndex !== -1) {
            this.getSelectedData.splice(existIndex, 1);
          }
        } else {
          if(!this.isChangeGetAll) {
            this.getSelectedData.length = 0;
          }
        }
      }
    },
    getSelectedData: {
      handler(newValue, oldValue) {
        if (newValue.length < this.itemCount) { // remove getAll when uncheck item
          this.isChangeGetAll = true;
          this.getAllData = false;
          // Reset variable selectAll, itemChecked when data selected  = 0
          if (!this.getSelectedData.length) {
            this.methodsList.map(item => {
              item.selectAll = false;
              if (item?.methods) {
                item?.methods?.map(itemLayer1 => {
                  if (itemLayer1?.methods) {
                    itemLayer1.selectAll = false;
                    itemLayer1.itemChecked = 0;
                    return item;
                  }
                })
              }
            })
          }
        } else {
          this.isChangeGetAll = false;
          this.getAllData = true;
          // if(this.getSelectedData.length === this.methods.length - 1) {
          //   this.getAllData = true
          // }
        }
      },
    },
    methods: {
      handler() {
        if (this.getSelectedData.length < this.itemCount) {
          this.getAllData = false;
          this.isChangeGetAll = true;
        }
      }
    },
    selectedData: {
      handler(value) {
        if (value.length === this.itemCount) {
          this.getSelectedData = [...value];
        }
      }
    },
    dialog() {
      if(this.dialog) {
        if (this.selectedData.length) {
          this.methodsList.map(async item => {
            item?.methods?.map(async itemLayer1 => {
              if (!itemLayer1?.methods) {
                const itemPush = `${item.text}_${itemLayer1.text}`;
                if (this.selectedData.includes(itemPush)) {
                  itemLayer1.selectAll = true;
                  itemLayer1.itemChecked = 1;
                }
              } else {
                itemLayer1?.methods?.map(itemLayer2 => {
                    const itemPush = `${item.text}_${itemLayer1.text}_${itemLayer2.text}`;
                    if (this.selectedData.includes(itemPush)) {
                      const numberChecked = itemLayer1.itemChecked;
                      itemLayer1.itemChecked = numberChecked + 1;
                      if (itemLayer1.itemChecked === itemLayer1.methods.length) this.$set(itemLayer1, 'selectAll', true);
                    }
                })
              }
              const itemMethodLenght = item?.methods?.length;
              const countItemMethodChecked = item?.methods?.filter(item => item.selectAll === true).length;
              if (itemMethodLenght === countItemMethodChecked) this.$set(item, 'selectAll', true);
            })
            return item;
          })
        } 
      }
    },
  },
  methods: {
    handleClickOutSite() {
      this.resetCheckedCountWhenClosePopup();
      this.$emit('close');
    },
    close() {
      this.resetCheckedCountWhenClosePopup();
      this.$emit('close');
      
    },
    submit() {
      this.resetCheckedCountWhenClosePopup();
      this.$emit('submit', this.getSelectedData);
      
      this.$emit('close');
    },
    getWidth() {
      if (window.innerWidth >= 1024) {
        return '464px';
      } else if (window.innerWidth >= 375) {
        return '335px';
      }
      return '100%';
    },
    onWindowResize() {
      window.addEventListener('resize', this.handleWindowResize);
    },
    handleWindowResize() {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(() => {
        this.boxWidth = this.getWidth();
      }, 100);
    },
    getMethodLayer1(index) {
      return this.methods[index]?.methods;
    },
    getMethodLayer2(layer1Index, index) {
      return this.methods[index]?.methods[layer1Index]?.methods;
    },
    getArrowImage() {
      return require('@/assets/icons/pulldown/arrow-down.svg');
    },
    toggleCollapse(index) {
      this.$set(this.isCollapsed, index, !this.isCollapsed[index]);
    },
    toggleCollapseLayer1(index) {
      this.$set(this.isCollapsedLayer1, index, !this.isCollapsedLayer1[index]);
    },
    isGetAll(text) {
      return text === (this.isReduction ? this.getAllText : GET_ALL);
    },
    isCheckedBox(text) {
      return this.getSelectedData.indexOf(text) !== -1;
    },
    isActiveMethod(text) {
      return this.getSelectedData.filter(item => item.includes(text)).length > 0
    },
    // Check selected item layer 2
    checkAllSelectedItemGr(event, index, layer1Index) {
      const dataItemLayer = this.methods[index];
      const dataItemChildLayer = dataItemLayer.methods[layer1Index];
      const titleItemLayer = dataItemLayer.text;
      const titleItemChildLayer = dataItemChildLayer.text;
      let dataitemPush;
      this.$set(dataItemChildLayer, 'selectAll', event.target.checked);
      if(event.target.checked) {
        dataItemChildLayer.itemChecked = dataItemChildLayer?.methods?.length;
        dataItemChildLayer?.methods?.map(itemLayer2 => {
          dataitemPush = `${titleItemLayer}_${titleItemChildLayer}_${itemLayer2.text}`;
          if (!this.getSelectedData.includes(dataitemPush)) {
            this.getSelectedData.push(dataitemPush);
          }
        })
      } else {
        dataItemChildLayer.itemChecked = 0;
        dataItemLayer.selectAll = false;
        dataItemChildLayer?.methods?.map(itemLayer2 => {
          dataitemPush = `${titleItemLayer}_${titleItemChildLayer}_${itemLayer2.text}`;
          const existIndex = this.getSelectedData.indexOf(dataitemPush);
          if (existIndex !== -1) {
            this.getSelectedData.splice(existIndex, 1);
          }
        })
      }
      this.checkedItemGr(event, dataItemLayer, index)
    },
    // Set count selected ietm layer 2
    selectedCheckbox(event, itemChild, item) {
      const itemChecked = itemChild.itemChecked;
      if(event.target.checked) {
        if (!itemChecked || itemChecked < 1) {
          this.$set(itemChild, 'itemChecked', 1)
        } else {
          itemChild.itemChecked = itemChecked + 1;
          if (itemChild.itemChecked === itemChild.methods.length) this.$set(itemChild, 'selectAll', true);
        }
      }  else {
       if (!itemChecked || itemChecked < 1) {
          this.$set(itemChild, 'itemChecked', 0)
        } else {
          itemChild.itemChecked = itemChecked - 1;
        }
        this.$set(itemChild, 'selectAll', false);
        this.$set(item, 'selectAll', false);
      }
      const itemMethodLenght = item?.methods?.length;
      const countItemMethodChecked = item?.methods?.filter(item => item.selectAll === true).length;
      if (itemMethodLenght === countItemMethodChecked) this.$set(item, 'selectAll', true);
    },
    // checked and set itemChecked when have data
    setCheckedCountWhenHasData(data) {
      if (!data.length) return;
      this.resetCheckedCountWhenClosePopup();
      data.map(item => {
        const nameItem = item.split("_");
        this.methodsList.map(async item => { 
          if(item?.text?.includes(nameItem[0])) {
            // item.selectAll = true;
            
            item?.methods?.map(itemLayer1 => {
              if (itemLayer1?.text.includes(nameItem[1])) {
                const itemChecked = itemLayer1.itemChecked;
                if (!itemChecked || itemChecked < 1) {
                  this.$set(itemLayer1, 'itemChecked', 1)
                } else {
                  itemLayer1.itemChecked = itemChecked + 1;
                  if (itemLayer1.itemChecked === itemLayer1.methods.length && itemLayer1.methods) {
                    this.$set(itemLayer1, 'selectAll', true);
                  } else {
                    this.$set(itemLayer1, 'selectAll', false);
                  }
                }
                return itemLayer1;
              }
            })
          }
          return item;
        })
      })
      return data;
    },
    // handel event check all check box
    handleCheckAll(event) {
      if(event.target.checked) {
        this.methodsList.map(item => { 
          this.$set(item, 'selectAll', true);
          item?.methods?.map(itemLayer1 => {
            if (!itemLayer1?.methods) {
              this.$set(itemLayer1, 'selectAll', true);
            } else {
              this.$set(itemLayer1, 'selectAll', true);
              this.$set(itemLayer1, 'itemChecked', itemLayer1.methods.length);
            }
            
          })
        });
      } else {
        this.methodsList.map(item => {
          item.selectAll = false;
          item.itemChecked = 0;
          item?.methods?.map(itemLayer1 => {
            itemLayer1.selectAll = false;
            itemLayer1.itemChecked = 0;
            return item;
          })
        })
      }
    },
    resetCheckedCountWhenClosePopup() {
      this.methodsList.map(item => { 
        item.selectAll = false;
        item?.methods?.map(itemLayer1 => {
          this.$set(itemLayer1, 'itemChecked', 0);
          this.$set(itemLayer1, 'selectAll', false);
          return itemLayer1;
        })
        return item;
      })
    },
    checkAllSelectedGr(event, index) {
      const dataItemGr = this.methodsList[index];
      this.$set(dataItemGr, 'selectAll', event.target.checked);

      if(event.target.checked) {
        this.methodsList.map((item ) => {
          if (item === dataItemGr) {
            item?.methods?.map(itemLayer1 => {
              if (!itemLayer1?.methods) {
                const itemPush = `${item.text}_${itemLayer1.text}`;
                if (!this.getSelectedData.includes(itemPush)) {
                  this.getSelectedData.push(itemPush);
                  this.$set(itemLayer1, 'selectAll', true);
                }
              } else {
                itemLayer1?.methods?.map(itemLayer2 => {
                  const itemPush = `${item.text}_${itemLayer1.text}_${itemLayer2.text}`;
                  if (!this.getSelectedData.includes(itemPush)) this.getSelectedData.push(itemPush);
                })
                this.$set(itemLayer1, 'selectAll', true);
                this.$set(itemLayer1, 'itemChecked', itemLayer1?.methods.length);
              }
            })
          }
        })
      } else {
        this.methodsList.map((item, methodsIndex ) => {
          if (methodsIndex === index) {
            item.selectAll = false;
            item?.methods?.map(itemLayer1 => {
              let dataitemPush, existIndex;
              if (!itemLayer1?.methods) {
                dataitemPush = `${item.text}_${itemLayer1.text}`;
                existIndex = this.getSelectedData.indexOf(dataitemPush);
                if (existIndex !== -1) {
                  this.getSelectedData.splice(existIndex, 1);
                }
              } else {
                itemLayer1?.methods?.map(itemLayer2 => {
                  dataitemPush = `${item.text}_${itemLayer1.text}_${itemLayer2.text}`;
                  existIndex = this.getSelectedData.indexOf(dataitemPush);
                  if (existIndex !== -1) {
                    this.getSelectedData.splice(existIndex, 1);
                  }
                })
              }
              itemLayer1.itemChecked = 0;
              itemLayer1.selectAll = false;
            })
          }
        })
      }
    },
    checkedItemGr(event, item, index, layer1Index) {
      const checked = event.target.checked;
      if (index != null && index != undefined  && layer1Index != null && layer1Index !== undefined) this.methodsList[index].methods[layer1Index].selectAll = checked;
      const itemMethodLenght = this.methodsList[index]?.methods?.length;
      const countItemMethodChecked = this.methodsList[index]?.methods?.filter(item => item.selectAll === true).length;
      
      if (checked && itemMethodLenght === countItemMethodChecked) {
        this.$set(item, 'selectAll', true);
      } else {
        this.$set(item, 'selectAll', false);
      }
    }
  },
};
</script>
<style lang="scss">
.checkbox-popup {
  width: 560px !important;
  max-width: 560px !important;
  max-height: calc(100vh - 320px) !important;
  background: $monoOffWhite;
  .divider {
    width: 100% !important;
  }
  .over-text-sp {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media only screen and (min-width: 375px) {
    .action-dialog-popup {
      &.button-bottom-dialog-popup {
        // padding-bottom: 0 !important;
        width: 100%;
        gap: 8px;
        .common-btn {
          width: calc((100% - 8px) / 2) !important;
          max-width: unset !important;
          margin-inline: 0 !important;
        }
      }
    }
    .common-btn {
      &.confirm {
        background: $goldMid !important;
        .v-btn__content{
          color: $monoWhite !important;
        }
      } 
    }
  }
  @media only screen and (min-width: 1024px) {
    .action-dialog-popup {
      &.button-bottom-dialog-popup {
        .common-btn {
          width: 100%;
          max-width: 268px !important;
        }
      }
    }
    .common-btn {
      &.confirm {
        background: $goldMid !important;
        .v-btn__content{
          color: $monoWhite !important;
        }
      } 
    }
  }
}
</style>
<style lang="scss" scoped>
@import '@/styles/components/popup/index.scss';
@import '@/components/dialogs/DialogPopupStyle/index.scss';
.checkbox-popup {
  .head-dialog-popup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // font-family: 'Source Han Sans JP';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: $monoBlack;
    background: $monoOffWhite;
    .selected-count {
      color: $monoMid;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0.05em;
    }
  }
  #style-scroll::-webkit-scrollbar {
    width: 16px;
  }

  #style-scroll::-webkit-scrollbar-thumb {
    background-color: $bgMid;
    border-radius: 10px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }
  #style-scroll::-webkit-scrollbar {
    width: 16px;
  }

  #style-scroll::-webkit-scrollbar-thumb {
    background-color: $bgMid;
    border-radius: 10px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }
  .selection-pulldown {
    max-height: calc(100vh - 320px - 113px);
    background: $monoOffWhite;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
    0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
    0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    overflow-y: hidden;
    overflow-x: hidden;
    width: 100%;
    // font-family: 'Source Han Sans JP';
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.03em;
    color: $monoBlack;
    z-index: 99;
    &.select-methods {
      overflow-y: scroll;
    }
    .item {
      min-height: 40px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-style: normal;
      display: flex;
      align-items: center;
      position: relative;
      border-right: 1px solid rgba(42, 42, 48, 0.1);
      border-left: 0;
      border-bottom: 0;
      & + .item {
        border-top: 1px solid rgba(42, 42, 48, 0.1);
      }
      .export-checkbox {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 7px 0px 9px 16px;
        white-space: normal;
        cursor: pointer;
        position: relative;
        input {
          appearance: none;
          width: 16px !important;
          height: 16px !important;
          background: #ffffff;
          border: 0.5px solid rgba(0, 0, 0, 0.25);
          box-shadow: inset 0px 0px 4px 2px rgba(0, 0, 0, 0.08);
          border-radius: 4px;
          cursor: pointer;
          margin-right: 8px;
          position: relative;

          &[type=checkbox]:checked {
            appearance: none;
            box-shadow: none;
            text-align: center;
            width: 16px !important;
            height: 16px !important;
            background: $blueMid;
          }
          &[type=checkbox]:checked:before {
            content: url('../../assets/icons/checkedbox.svg');
            color: $monoWhite;
            position: absolute;
            top: -6px;
            left: 0;
          }
        }
        label {
          width: calc(100% - 40px);
          cursor: pointer;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        
        &:hover {
          background: $bgLight;
          font-weight: 500;
        }
        &.is-checked {
          &::before {
            content: '';
            height: 100%;
            border: 2px solid $blueMid;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
      .toggle-wrapper {
        width: 100%;
        cursor: pointer;
        
        &.layer1,&.layer2 {
          border-left: 0;
          border-right: 0;
        }
        .toggle-label {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 7px 14px 9px 40px;
          white-space: normal;
          &.export-checkbox-multi-method {
            padding: 7px 14px 9px 16px;
          }
          .export-checkbox{
            padding: 0;
            background: transparent;
            &::before{
              display: none;
            }
          }
          &.is-checked {
            &::before {
              content: "";
              height: 40px;
              border: 2px solid $blueMid;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
          .toggle-icon {
            width: 15px;
            height: 10px;
            &.arrow-top {
              rotate: (180deg);
            }
          }
        }
        &.layer1 {
          .item {
            border-right: 0;
          }
        }
        
        &.layer1{
          .export-checkbox {
            padding: 7px 0px 9px 40px;
          }
        }
        .content-container {
          border: 1px solid rgba(42, 42, 48, 0.1);
          border-bottom: 0;
          border-left: 0;
          border-right: 0;
          &.export-checkbox-multi-method{
            .export-checkbox {
              padding: 7px 0px 9px 65px;
            }
          }
        }
      }
    }
    .search-text {
      position: relative;
      background: $monoWhite;
      .input-elic {
        width: 100% !important;
        margin: unset;
        box-shadow: unset;
        background: $monoWhite;
      }
      .search-icon {
        position: absolute;
        right: 9px;
        top: 10px;
        width: 20px;
        height: 20px;
      }
    }

    &.dont-collapse {
      .item {
        .export-checkbox {
          padding: 7px 0px 9px 16px;
        }
        .layer1 {
          .export-checkbox {
            padding: 7px 0px 9px 16px;
          }
          .layer2 {
            .toggle-label {
              display: block;
              padding: 7px 14px 9px 16px;
              font-size: 13px;
              font-weight: 700;
              letter-spacing: 0.05em;
              color: $monoBlack;
              background: rgba(121, 134, 134, 0.12);
              &.is-checked {
                &::before {
                  content: "";
                  height: 40px;
                  border: 2px solid $blueMid;
                  position: absolute;
                  top: 0;
                  left: 0;
                }
              }
            }
            .export-checkbox {
              padding: 7px 14px 9px 16px;
            }
          }
        }
      }
    }
    .v-virtual-scroll::-webkit-scrollbar {
      width: 16px;
    }
    .v-virtual-scroll::-webkit-scrollbar-thumb {
      background-color: #bfd4d9;
      border-radius: 10px;
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
    }
  }
}
.is-checked-getall {
  position: relative;
  &::before {
    content: '';
    height: 40px;
    border: 2px solid $blueMid;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
